<template>
  <div class="table-skeleton">
    <template v-for="key in 6">
      <div :key="key" class="row"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: "tableSkeleton"
}
</script>

<style lang="scss" scoped>
.table-skeleton {
  padding-top: 5px;

  .row {
    border-radius: var(--border-radius);
    height: calc(var(--l-h) + 20px);
    margin-bottom: 5px;
    background-color: var(--bg);
  }
}
</style>
