<template>
  <div class="controller-card-skeleton">
    <div class="card-header">
      <div class="card-badge"></div>
    </div>
    <div class="card-body">
      <div class="title"></div>
      <template v-for="key in 2">
        <div :key="key" class="card-item">
          <div class="icon"></div>
          <div class="label"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "controllerCardSkeleton"
}
</script>

<style lang="scss" scoped>
.controller-card-skeleton {
  padding: 10px;
  border-radius: var(--border-radius);
  border: 1px solid var(--bg);

  .card-header {
    border-bottom: 1px solid var(--bg);

    .card-badge {
      border-radius: 0 .5rem 0 35px;
      font-size: 14px;
      margin-left: auto;
      position: relative;
      left: 5px;
      bottom: 5px;
      height: 40px;
      width: 80px;
      background-color: var(--bg);
    }
  }

  .card-body {
    padding-top: 10px;

    .title {
      height: calc(var(--l-h) + 5px);
      border-radius: var(--border-radius);
      background-color: var(--bg);
    }

    .card-item {
      display: flex;
      align-items: center;
      margin-top: 5px;

      .icon {
        height: calc(var(--l-h));
        width: calc(var(--l-h));
        border-radius: 50%;
        background-color: var(--bg);
      }

      .label {
        flex: 1;
        margin-left: 10px;
        height: calc(var(--l-h));
        border-radius: var(--border-radius);
        background-color: var(--bg);
      }
    }
  }
}
</style>
