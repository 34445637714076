<template>
  <div class="chart-column-skeleton">
    <div class="chart-box">
      <template v-for="key in cols">
        <div class="chart-column" :style="[{height: `${Math.random() * 100}%`}]"></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "chartColumnSkeleton",
  props: {
    cols: {
      type: Number,
      default: 3
    }
  },
  computed: {

  }
}
</script>

<style lang="scss" scoped>
.chart-column-skeleton {
  height: 100%;

  .chart-box {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 15px;
    padding: 10px;

    .chart-column {
      border-radius: var(--border-radius);
      width: var(--c-w);
      background-color: var(--bg);
    }
  }
}
</style>
