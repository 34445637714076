<template>
  <div class="address-card-skeleton">
    <div class="card-header">
      <div class="address-title"></div>
      <div class="address-icon"></div>
    </div>
    <div class="card-body">
      <template v-for="key in 3">
        <div :key="key" class="card-item">
          <div class="icon"></div>
          <div class="label"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "addressCardSkeleton"
}
</script>

<style lang="scss" scoped>
.address-card-skeleton {
  width: 100%;
  padding: 10px;
  border-radius: 0.5rem;
  border: 1px solid var(--bg);

  .card-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--bg);
    padding-bottom: 10px;

    .address-title {
      height: calc(var(--l-h) + 5px);
      width: 50%;
      border-radius: var(--border-radius);
      background-color: var(--bg);
    }

    .address-icon {
      height: calc(var(--l-h) + 5px);
      width: calc(var(--l-h) + 5px);
      border-radius: 50%;
      background-color: var(--bg);
    }
  }
  .card-body {
    .card-item {
      display: flex;
      margin: 5px 0;

      .label {
        height: calc(var(--l-h));
        width: 50%;
        border-radius: var(--border-radius);
        background-color: var(--bg);
      }

      .icon {
        height: calc(var(--l-h));
        width: calc(var(--l-h));
        margin-right: 10px;
        border-radius: 50%;
        background-color: var(--bg);
      }
    }
  }
}
</style>
