<template>
  <div class="treasury-doc-skeleton">
    <div class="treasury-doc-section">
      <div class="section-title"></div>
      <tree-skeleton :lines="1" />
    </div>
    <div class="treasury-doc-section">
      <div class="section-title"></div>
      <tree-skeleton :lines="1" />
    </div>
    <div class="treasury-doc-section">
      <div class="section-title"></div>
      <tree-skeleton :lines="3" />
    </div>
  </div>
</template>

<script>
import TreeSkeleton from "@/components/customSkeleton/treeSkeleton.vue";

export default {
  name: "treasuryDocSkeleton",
  components: {TreeSkeleton}
}
</script>

<style lang="scss" scoped>
.treasury-doc-skeleton {
  --border-radius: 0;
  .treasury-doc-section {
    margin-bottom: 15px;
    padding-top: 15px;

    .section-title {
      margin-bottom: 10px;
      height: calc(var(--l-h) + 5px);
      width: 150px;
      border-radius: 0.5rem;
      background-color: var(--bg);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
